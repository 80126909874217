import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import cookies from "vue-cookies";
import BASE from "@/store/api-endpoint";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "FeeAssignment" }
  },
  {
    name: "Authentication",
    path: "/auth",
    component: () => import("@/views/Authentication.vue"),
    props: true,
    meta: {
      title: "Sign In"
    },
  },
  {
    name: "ForgotPassword",
    path: "/auth/forgot-password",
    component: () => import("@/views/ForgotPassword.vue"),
    meta: {
      title: "Reset Password"
    },
  },
  {
    name: "FeeAssignment",
    path: "/entities",
    component: () => import("@/views/BrowseEntities.vue"),
    children: [
      {
        name: "HierarchyTree",
        path: "/entities/:hierarchy/:id",
        component: () => import("@/components/browse-entities/HierarchyTree.vue"),
        props: true,
        meta: {
          title: "Hierarchy Tree"
        },
      },
      {
        name: "ModifyEntity",
        path: "/entities/:hierarchy/:id/modify",
        component: () => import("@/views/AddUpdateEntity.vue"),
        props: true,
        meta: {
          title: "Modify Entity"
        },
      },
      {
        name: "FeeSchedules",
        path: "/entities/:hierarchy/:id/fee-schedule",
        component: () => import("@/views/FeeSchedule.vue"),
        props: true,
        meta: {
          title: "Fee Schedules"
        },
      },
      {
        name: "AddGroup",
        path: "/entities/:hierarchy/:id/add-group",
        component: () => import("@/views/AddGroup.vue"),
        props: true,
        meta: {
          title: "Add New Group"
        },
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "BrowseNewEntities",
    path: "/entities/new",
    component: () => import("@/views/NewEntities.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Profile",
    path: "/profile",
    component: () => import("@/views/UserProfile.vue"),
    meta: {
      title: "Profile",
      requiresAuth: true
    }
  },
  {
    name: "ProfileChangePassword",
    path: "/profile/change-password",
    component: () => import("@/views/ChangePassword.vue"),
    meta: {
      title: "Change Password",
      requiresAuth: true
    }
  },
  {
    name: "DownloadPortal",
    path: "/download-portal",
    component: () => import("@/views/DownloadPortal.vue"),
    meta: {
      title: "Download Portal",
      requiresAuth: true
    }
  },
  {
    name: "FeeResults",
    path: "/fee-results",
    redirect: { name: "FeeResultsAccounts" },
    component: () => import("@/views/FeeResults.vue"),
    children: [
      {
        name: "FeeResultsAccounts",
        path: "accounts",
        component: () => import("@/components/fee-results/FeeResultsAccounts.vue"),
        meta: {
          title: "Fee Review"
        },
      },
      {
        name: "FeeResultsRecords",
        path: "records",
        component: () => import("@/components/fee-results/FeeResultsRecords.vue"),
        props: true,
        meta: {
          title: "Review Fees and Schedules"
        },
      },
      {
        name: "FeeResultsExports",
        path: "exports",
        component: () => import("@/components/fee-results/FeeResultsExports.vue"),
        meta: {
          title: "Fee Results - Exports"
        },
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "InceptionWizard",
    path: "/inception-wizard",
    component: () => import("@/views/InceptionBilling.vue"),
    meta: {
      title: "Inception Billing",
      requiresAuth: true
    }
  },
  {
    name: "TerminationWizard",
    path: "/termination-wizard",
    component: () => import("@/views/TerminationBilling.vue"),
    meta: {
      title: "Termination Billing",
      requiresAuth: true
    }
  },
  {
    name: "AccountStatuses",
    path: "/account-statuses",
    redirect: { name: "AccountStatus" },
    component: () => import("@/views/AccountStatuses.vue"),
    children: [
      {
        name: "AccountStatus",
        path: "",
        component: () => import("@/components/account-status/AccountStatus.vue"),
        meta: {
          title: "Account Status"
        },
      },
      {
        name: "InvoiceScreen",
        path: "invoices",
        component: () => import("@/components/account-status/InvoiceScreen.vue"),
        meta: {
          title: "Invoice History"
        },
      },
      {
        name: "PayoutScreen",
        path: "payouts",
        component: () => import("@/components/account-status/PayoutScreen.vue"),
        meta: {
          title: "Payouts"
        },
      },
      {
        name: "FullStatusHistory",
        path: "history",
        component: () => import("@/components/account-status/FullStatusHistory.vue"),
        meta: {
          title: "Status History"
        },
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "ModelMaintenance",
    path: "/model-maintenance",
    component: () => import("@/views/ModelMaintenance.vue"),
    meta: {
      title: "Model Maintenance",
      requiresAuth: true
    }
  },
  {
    name: "EntityNotFound",
    path: "/not-found",
    component: () => import("@/views/EntityNotFound.vue"),
    meta: {
      title: "Not Found. Redirecting..."
    },
  },
  {
    name: "RootNotFound",
    path: "/root-not-found",
    component: () => import("@/views/RootNotFound.vue"),
    meta: {
      title: "Not Found. Redirecting..."
    },
  },
  {
    name: "PageNotFound",
    path: "*",
    component: () => import("@/views/PageNotFound.vue"),
    meta: {
      title: "Not Found. Redirecting..."
    },
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.afterEach((to) => {
  store.dispatch("pageTitle/changePageTitle", to);
});

router.beforeEach((to, from, next) => {
  store.dispatch("appNavigation/setCurrentApiEnvironment");
  const workInProgress = store.state.addAccountGroup.unsavedInputValue;
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (cookies.isKey("username") && cookies.isKey("token") && cookies.isKey("organization")) {
      if (from.name === "AddGroup" && workInProgress) {
        store.state.addAccountGroup.unsavedInputValueDialog = true;
        store.state.addAccountGroup.nextRoute = to.name;
        return;
      }
      if (!store.state.auth.user || !store.state.auth.user.username) {
        //User opened new tab, set settings
        let org = cookies.get("organization");
        const organization = BASE.organization;
        let baseUrl = organization[org] ? organization[org].urlBase : null;
        let calcUrl = organization[org] ? organization[org].urlCalc : null;
        store.state.endpoints.baseUrl = baseUrl;
        store.state.endpoints.baseUrlCalc = calcUrl;

        let user = {
          username: cookies.get("username"),
          organization: cookies.get("organization"),
          email: cookies.get("email")
        };
        store.state.auth.user = user;
        // Need this because of mapping fee types to Display names
        store.dispatch("feeSchedule/getBillingFeeTypes").then(() => next());
        // daily import info (duration, etc...)
        store.dispatch("backgroundMessages/getDailyImportSchedule").then(() => next());
      } else if (cookies.get("username") !== store.state.auth.user.username) {
        //Other user logged in in other tab, change settings
        let org = cookies.get("organization");
        const organization = BASE.organization;
        let baseUrl = organization[org] ? organization[org].urlBase : null;
        let calcUrl = organization[org] ? organization[org].urlCalc : null;
        store.state.endpoints.baseUrl = baseUrl;
        store.state.endpoints.baseUrlCalc = calcUrl;

        let user = {
          username: cookies.get("username"),
          organization: cookies.get("organization"),
          email: cookies.get("email")
        };
        store.state.auth.user = user;
        store.state.auth.userChanged = true;
      } else {
        //User didn't change, show next page
        // Need this because of mapping fee types to Display names
        store.dispatch("feeSchedule/getBillingFeeTypes").then(() => next());
        // daily import info (duration, etc...)
        store.dispatch("backgroundMessages/getDailyImportSchedule").then(() => next());
      }
    } else {
      console.log("User is not authenticated, redirecting...");
      router.replace({
        name: "Authentication",
        params: {
          redirectTo: to
        }
      });
    }
  } else if (to.name === "Authentication" || to.name === "ForgotPassword") {
    if (cookies.isKey("username") && cookies.isKey("token") && cookies.isKey("organization")) {
      //User is already logged in, there is no need to show them Authentication page
      router.replace("/");
    } else {
      //User is NOT authenticated, show them the page
      next();
    }
  } else {
    next();
  }
});

export default router;
