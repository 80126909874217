import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import Amplify from "aws-amplify";
import "@aws-amplify/ui-vue";
import aws_exports from "./aws-exports";

import VueCookies from "vue-cookies";
import cookies from "vue-cookies";
import VueClipboards from "vue-clipboards";

import LogRocket from "logrocket";

Amplify.configure(aws_exports);

Vue.config.productionTip = false;
Vue.config.ignoredElements = ["smartx-model-maintenance"];

Vue.use(VueCookies);
Vue.use(VueClipboards);

LogRocket.init("hqtqlf/smartx-billpro");
LogRocket.identify("User From BillFix", {
  name: cookies.get("username"),
  organization: cookies.get("organization"),

});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created() {
    window.addEventListener("beforeunload", this.onClose);
    window.addEventListener("load", this.onLoad);
  },
  methods: {
    onLoad(event) {
      event.returnValue = "";

      if (this.$cookies.isKey("username")) {
        console.log("User signed in:", this.$cookies.get("username"));
      }
    },
    onClose(event) {
      event.returnValue = "";
    }
  }
}).$mount("#app");
